.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 600px;
    overflow-y: auto;
  }
  
  h1, h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  p {
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
  }
  
  ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  