.media-gallery {
  margin-top: 20px;
  padding: 0 10px;
}

.media-item {
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.photo-item, .video-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.italic-message {
  font-style: italic;
  margin: 20px 0;
  color: #4A4A4A;
  text-align: center;
}

.cover-photo-container {
  text-align: center;
  margin: 20px 0;
}

.cover-photo {
  border-radius: 50%; /* Make the image circular */
  width: 200px;       /* Set the width */
  height: 200px;      /* Set the height to match the width */
  object-fit: cover;  /* Ensures the image covers the container without stretching */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .cover-photo {
    width: 150px;  /* Decrease size on smaller screens */
    height: 150px; /* Match height to width to keep circular */
  }
}

@media (max-width: 480px) {
  .cover-photo {
    width: 120px;  /* Further decrease size on very small screens */
    height: 120px; /* Match height to width to keep circular */
  }
}

.upload-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
}

.section-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 5px;
}

.event-title {
  text-align: center;
  margin-bottom: 10px;
}

.event-date {
  text-align: center;
  margin-bottom: 20px;
}

.event-header {
  margin-bottom: 20px;
  text-align: center;
}

/* Full-Screen Swipeable Photo Viewer */
.swipeable-photo-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.photo-fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.photo-fullscreen img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

/* Add hover effect on media items */
.media-item:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.photo-item:hover, .video-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
